import React from 'react'
import SEOTools from '../../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import HeroUnit from '../../components/HeroUnit'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const CareersPage = ({ data }) => {
    const remoteworkingBg = getImage(data.remoteworkingBg)

    return (
        <Layout>
            <SEOTools
                title='Careers'
                description="At Siru you don't have to sit in office, you can work wherever you want. We love remote work and hope you do too."
                image={getSrc(data.heroBg.childImageSharp)}
            />
            <HeroUnit backgroundImage={data.heroBg} className='dark-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Careers</h1>
                    </Col>
                </Row>
            </HeroUnit>
            <section>
                <Container className='py-5'>
                    <Row>
                        <Col md='3 text-center pb-5'>
                            <StaticImage
                                src='../../images/sirumobile_careers_suomenvahvimmat.png'
                                alt='Suomen vahvimmat -logo'
                                placeholder='blurred'
                                width={180}
                            />
                        </Col>
                        <Col md='8'>
                            <h2>Siru isn't a workplace, it's a lifestyle!</h2>
                            <p>
                                You've seen millions of bad user experiences, and you're sick of them. Do you want to
                                make financial services more accessible for everyone without having to pay a hundred
                                thousand euros?
                            </p>
                            <p>
                                Siru Mobile is an innovative tech company. We're a bunch of diverse people who together
                                move fast and aim high. We live and breathe tech. We love to travel. Sometimes we even
                                do it together. We're passionate about what we do, and we encourage out of the box
                                -thinking. We're driven, and we get easily excited. We get a kick out of learning new
                                things. We try. We fail. We succeed. And we celebrate our successes together.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container className='py-5 my-5'>
                        <Row className='justify-content-center'>
                            <Col md='10'>
                                <h2>Freedom of working</h2>
                                <p className='spaced-light'>
                                    At Siru, work is a more relaxed affair. You can easily do your job from anywhere in
                                    the world as long as you have an internet connection. We believe remote working
                                    should be for everyone, so we're always looking to make it easier for our teams to
                                    get their jobs done wherever they are!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={remoteworkingBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center my-5 py-5'>
                        <Col xs='10'>
                            <h2 className='mb-5'>Currently open positions</h2>
                            <hr className='mb-4' />
                            <Row className='justify-content-center'>
                                <Col xl='8' lg='7' md='6' sm='12'>
                                    <p className='py-2'>Part time Financial Coordinator</p>
                                </Col>
                                <Col>
                                    <Link
                                        to='/careers/part-time-financial-coordinator-finland-and-sweden/'
                                        className='btn btn-success btn-lg btn-block px-5 rounded-0'>
                                        See job posting
                                    </Link>
                                </Col>
                            </Row>
                            <hr className='mb-5' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "sirumobile_career_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        remoteworkingBg: file(relativePath: { eq: "sirumobile_careers_remoteworking.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default CareersPage
